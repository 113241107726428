import React from "react";
import { Link } from "react-router-dom";

export default function NextButton(props) {
  const { position } = props;

  console.log("position.next", position.next);

  if (position.next === "0") {
    return "";
  }

  return (
    <Link
      className="garden-next-button"
      title="volgende tuin"
      to={"/" + position.next}
    >
      <i className="fa fa-chevron-right"></i>
    </Link>
  );
}
