import React from "react";

function handleModal(props, images, index) {
  props.setOpenImageModal(true);
  props.setImageData(images);
  props.setImageIndex(index);
}

export default function GardenImage(props) {
  const { index, gardenData } = props;
  const { garden, images } = gardenData;

  if (!images || images.length === 0) {
    return "";
  }

  const image = images[index];

  if (image.title === "") {
    image.title = garden.address;
  }

  return (
    <img
      src={process.env.REACT_APP_IMAGE_DIR + image.image}
      alt={image.title}
      title={image.title}
      className="gardenimage-image"
      onClick={() => {
        handleModal(props, images, index);
      }}
    ></img>
  );
}
