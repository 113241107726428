//import React, { useState, useEffect } from "react";
import "../App.css";
import "./Info.css";

export default function Info(props) {
  const { text } = props;

  if (!text) {
    return "";
  }

  const {
    info_header_main = "",
    info_intro = "",
    info_parking_header = "",
    info_parking_text = "",
    info_horeca_header = "",
    info_horeca_text = "",
    info_also_header = "",
    info_also_text = "",
  } = text;

  return (
    <>
      <span id="info"></span>
      <div className="info">
        <div className="info-story">
          <h1 className="info-header">{info_header_main.text}</h1>
          <p className="info-description">{info_intro.text}</p>
          <h2 className="info-header">{info_parking_header.text}</h2>
          <p>{info_parking_text.text}</p>
          <h2 className="info-header">{info_horeca_header.text}</h2>
          <p>{info_horeca_text.text}</p>
          <h2 className="info-header">{info_also_header.text}</h2>
          <p>{info_also_text.text}</p>
        </div>
      </div>
    </>
  );
}
