import React from "react";
import "./ImageModal.css";

export default function ImageModal({
  closeImageModal,
  setImageIndex,
  imageData,
  imageIndex,
}) {
  let image = imageData[imageIndex];

  function nextImage(setImageIndex, items, index) {
    index = index + 1;
    index = index % items.length;
    setImageIndex(index);
  }

  function prevImage(setImageIndex, items, index) {
    if (index === 0) {
      // i would become 0
      index = items.length; // so put it at the other end of the array
    }
    index = index - 1; // decrease by one
    setImageIndex(index);
  }

  return (
    <div className="imagemodal-background">
      <div
        className="imagemodal-prev"
        title="vorige foto"
        onClick={() => prevImage(setImageIndex, imageData, imageIndex)}
      >
        <i className="fa fa-chevron-left"></i>
      </div>
      <div className="imagemodal-container">
        <div
          className="imagemodal-close"
          title="terug naar tuin"
          onClick={() => closeImageModal(false)}
        >
          <i className="fa fa-times"></i>
        </div>
        <img
          className="imagemodal-image"
          src={process.env.REACT_APP_IMAGE_DIR + image.image}
          alt={image.title}
          onClick={() => closeImageModal(false)}
        ></img>
        <p className="imagemodal-caption">
          {image.title}
          <span className="imagemodal-copyright">
            {image.copyright ? " ©" + image.copyright : ""}
          </span>
        </p>
      </div>
      <div
        className="imagemodal-next"
        title="volgende foto"
        onClick={() => nextImage(setImageIndex, imageData, imageIndex)}
      >
        <i className="fa fa-chevron-right"></i>
      </div>
    </div>
  );
}
