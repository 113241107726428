import React from "react";
import "./Map.css";

export default function Map() {
  return (
    <div className="map" id="map">
      <h1>&nbsp;</h1>
      <iframe
        className="map-frame"
        src="https://www.google.com/maps/d/embed?mid=1xnvUBglLAQ-jFS2wyHo99XbDV6xh5GE&z=13&ll=52.4573%2C4.7927"
        title="Kaart Opentuinendag Westzaan 2023"
        loading="lazy"
      ></iframe>
    </div>
  );
}
