import React from "react";
import { Link } from "react-router-dom";
import "./GardenListItem.css";

function GardenListItem(props) {
  const { garden } = props;

  //console.log("garden", garden);

  return (
    <div className="gardenitemlist-garden">
      <Link className="gardenitemlist-link" to={"/" + garden.id}>
        <figure
          className="gardenitemlist-figure"
          data-category={garden.address}
        >
          <img
            className="gardenitemlist-image"
            alt={garden.address}
            src={process.env.REACT_APP_IMAGE_DIR + garden.image}
          />
        </figure>
        <div className="gardenitemlist-info">
          <p className="gardenitemlist-text">{garden.intro}</p>
        </div>
      </Link>
    </div>
  );
}

export default GardenListItem;
