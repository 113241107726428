import React from "react";
import "../App.css";
import "./HeroSection.css";

export default function HeroSection(props) {
  const { text } = props;

  if (!text) {
    return "";
  }

  const { hero_title = "", hero_date = "", hero_time = "" } = text;
  return (
    <>
      <span id="top"></span>
      <div className="hero-container">
        <video src="./videos/flower.mp4" autoPlay loop muted />
        <h1>{hero_title.text}</h1>
        <p>{hero_date.text}</p>
        <p>{hero_time.text}</p>
      </div>
    </>
  );
}
