import React, { useState, useEffect } from "react";
import ImageModal from "./ImageModal";
import GardenImage from "./GardenImage";
import "../../App.css";
import "./Garden.css";
import NextButton from "./NextButton";
import PrevButton from "./PrevButton";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

export default function Garden() {
  const { id } = useParams();
  const [openImageModal, setOpenImageModal] = useState(false);
  const [imageData, setImageData] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const [gardenData, setGardenData] = useState([]);

  useEffect(() => {
    const url = process.env.REACT_APP_API_URL + "?t=garden&id=" + id;
    console.log("url", url);

    const getData = () => {
      fetch(url, {
        crossDomain: true,
      })
        .then((response) => {
          return response.json();
        })
        .then((myJson) => {
          setGardenData(myJson);
        });
    };

    getData();
  }, [id]);

  const { garden, images, position } = gardenData;

  if (!garden || garden.length === 0) {
    //todo: add a nice error or default page
    return <p>...</p>;
  }

  //replace single linebreaks with double linebreaks.
  //easier on the eye.
  const description = garden.description.replace(
    /(^|[^\n])\n([^\n]|$)/g,
    "$1\n\n$2"
  );

  return (
    <div className="garden-main">
      <div className="garden-wrapper">
        <div className="garden-caption">
          <PrevButton position={position} />
          <h1 className="garden-header">{garden.address}</h1>
          <NextButton position={position} />
        </div>
        {garden.owner && (
          <div className="garden-owner">Tuin van {" " + garden.owner}</div>
        )}
        <p className="garden-description">{description}</p>
        <div className="garden-images">
          {images.map((image, index) => (
            <GardenImage
              key={image.id}
              index={index}
              gardenData={gardenData}
              setOpenImageModal={setOpenImageModal}
              setImageData={setImageData}
              setImageIndex={setImageIndex}
              openImageModal={openImageModal}
            />
          ))}
        </div>
        {garden.favourite_plants && (
          <>
            <h2 className="garden-header">Favoriete plant(en)</h2>
            <p className="garden-description">{garden.favourite_plants}</p>
          </>
        )}
        <h2 className="garden-header">Extra informatie</h2>
        <ul className="garden-extra">
          {garden.additional_info && (
            <li className="garden-extras">{garden.additional_info}</li>
          )}
          {garden.size && (
            <li className="garden-extras">
              Onze tuin is {" " + garden.size} m<sup>2</sup> groot.
            </li>
          )}
          <li className="garden-extras">
            Het volledige adres is:
            {" " +
              garden.address +
              ", " +
              garden.zip +
              " " +
              garden.municipality}
          </li>
        </ul>
      </div>
      {openImageModal && (
        <ImageModal
          closeImageModal={setOpenImageModal}
          setImageIndex={setImageIndex}
          imageData={imageData}
          imageIndex={imageIndex}
        />
      )}
    </div>
  );
}
