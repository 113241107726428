import React, { useEffect, useState } from "react";
import "../../App.css";
import GardenList from "../GardenList";
import HeroSection from "../HeroSection";
import Footer from "../Footer";
import Map from "../Map";
import Info from "../Info";

function Home() {
  const [textData, setTextData] = useState([]);

  useEffect(() => {
    const url = process.env.REACT_APP_API_URL + "?t=text&id=0";
    //console.log(url);

    const getData = () => {
      fetch(url, {
        crossDomain: true,
      })
        .then((response) => {
          return response.json();
        })
        .then((myJson) => {
          setTextData(myJson);
        });
    };

    getData();
  }, []);

  const text = textData[0];

  return (
    <>
      <HeroSection text={text} />
      <GardenList />
      <Info text={text} />
      <Map />
      <Footer />
    </>
  );
}

export default Home;
