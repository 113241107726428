import React from "react";
import { Link } from "react-router-dom";

export default function PrevButton(props) {
  const { position } = props;

  console.log("position.prev", position.prev);

  if (position.prev === "0") {
    return "";
  }

  return (
    <Link
      className="garden-prev-button"
      title="vorige tuin"
      to={"/" + position.prev}
    >
      <i className="fa fa-chevron-left"></i>
    </Link>
  );
}
