import React, { useState, useEffect } from "react";
import "./GardenList.css";
import GardenListItem from "./GardenListItem";

export default function GardenList() {
  const [gardenData, setGardenData] = useState([]);

  useEffect(() => {
    const url = process.env.REACT_APP_API_URL + "?t=garden";
    console.log(url);

    const getData = () => {
      fetch(url, {
        crossDomain: true,
      })
        .then((response) => {
          return response.json();
        })
        .then((myJson) => {
          console.log("setdata");
          setGardenData(myJson);
        });
    };

    getData();
  }, []);

  const gardens = gardenData;

  if (!gardens || gardens.length === 0) {
    return <p>...</p>;
  }

  return (
    <>
      <span id="gardens"></span>
      <div className="gardenlist-gardens">
        {gardens.map((garden) => (
          <GardenListItem key={garden.id} garden={garden} />
        ))}
      </div>
    </>
  );
}
